nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
}

nav ul {
    font-size: 1.25em;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

nav ul li a {
    display: inline-block;
    color: black;
    text-decoration: none;
    padding: 1rem;
}

h1 {
    margin: 0;
    padding: 1rem;
    font-size: 1.75em;
}