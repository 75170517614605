.image-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper {
  width: 90vw;
  height: 90vh;
}

.image {
  width: 90vw;
  height: 90vh;
  object-fit: contain;
  opacity: 0;
}

.image.show {
  opacity: 1;
}

.image-placeholder {
  width: 90vw;
  height: 90vh;
  object-fit: contain;
  position: absolute;
  opacity: 1;
  filter: blur(7px) grayscale(1);
}

.image-placeholder.hidden {
  opacity: 0;
}

.image-caption {
  text-align: center;
  font-size: 0.9em;
  padding: 0.75em;
}

@media (max-width: 800px) {
  .image-container {
    width: auto;
    height: auto;
    padding-bottom: 1rem;
  }

  .image-wrapper {
    width: 100vw;
    height: auto;
  }

  .image {
      width: 100vw;
      height: auto;
  }

  .image-placeholder {
    width: 100vw;
    height: auto;
  }
}
