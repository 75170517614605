.about {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__inner {
  max-width: 800px;
  padding: 1rem;
}

@media (max-width: 800px) {
  .about {
    height: auto;
    width: auto;
  }

  .about__inner {
    padding: 4rem 1rem;
  }
}