html {
  overflow-x: hidden;
}

body {
  margin: 0;
}

a {
  color: black;
}